var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"release"},[_c('TabHeader',{attrs:{"active":3}}),_c('div',{staticClass:"goods"},_vm._l((_vm.goodsList),function(item){return _c('div',{key:item.Id,staticClass:"goods_item",on:{"click":function($event){_vm.dialogVisible = true}}},[(item.DetailImage)?_c('img',{staticClass:"item_img",attrs:{"src":item.DetailImage.split(',')[0].indexOf('cosgz') > -1
            ? item.DetailImage.split(',')[0].replace(
                'cosgz',
                'cos.ap-guangzhou'
              ) + '/pc_img'
            : item.DetailImage.split(',')[0],"alt":""}}):(item.Image)?_c('img',{staticClass:"item_img",attrs:{"src":item.Image.indexOf('cosgz') > -1
            ? item.Image.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
            : item.Image,"alt":""}}):_vm._e(),_c('div',{staticClass:"item_name"},[_vm._v(_vm._s(item.Name || '--'))]),_c('div',{staticClass:"item_price"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"price"},[_vm._v("￥"+_vm._s(item.Price || 0))]),_c('div',[_vm._v("零售参考")])]),_c('div',{staticClass:"two"},[_c('div',[_c('div',{staticClass:"price"},[_vm._v(" ￥"+_vm._s(item.OutPrice2 || item.OutPrice || 0)+" ")]),_c('div',[_vm._v("批发参考")])]),_c('div',[_c('div',{staticClass:"price"},[_vm._v(" "+_vm._s(item.MinAmount || 0)+" "),(item.Category1 == '酒类')?_c('span',[_vm._v("瓶")]):(item.Category1 == '茶叶')?_c('span',[_vm._v("斤")]):(item.Category1 == '其他')?_c('span',[_vm._v(" "+_vm._s(item.Name2 || '')+" ")]):_vm._e()]),_c('div',[_vm._v("起批量")])])])]),_c('div',{staticClass:"item_merchants"},[_c('img',{staticClass:"merchants_img",attrs:{"src":item.StoreHeadImage || _vm.defaultPic,"alt":"","srcset":""}}),_c('div',{staticClass:"merchants_info"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.StoreName || '--'))]),_c('div',{staticClass:"address"},[_c('i',{staticClass:"el-icon-location"}),_vm._v(" "+_vm._s(item.Province)+_vm._s(item.City)+_vm._s(item.Area)+" ")])])])])}),0),(_vm.total > 12)?_c('div',{staticClass:"paging"},[_c('el-pagination',{attrs:{"page-size":12,"pager-count":5,"layout":"prev, pager, next, slot","total":_vm.total,"hide-on-single-page":true},on:{"current-change":_vm.changeCurrent}})],1):_vm._e(),_c('AllFooter',{ref:"AllFooter"}),_c('el-dialog',{attrs:{"title":" ","visible":_vm.dialogVisible,"width":"400px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{staticStyle:{"width":"320px","margin":"36px auto 0"},attrs:{"src":require("../../assets/img/code3.png"),"alt":"","srcset":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }