<template>
  <div class="release">
    <TabHeader :active="3"></TabHeader>

    <div class="goods">
      <div
        class="goods_item"
        v-for="item in goodsList"
        :key="item.Id"
        @click="dialogVisible = true"
      >
        <img
          class="item_img"
          v-if="item.DetailImage"
          :src="
            item.DetailImage.split(',')[0].indexOf('cosgz') > -1
              ? item.DetailImage.split(',')[0].replace(
                  'cosgz',
                  'cos.ap-guangzhou'
                ) + '/pc_img'
              : item.DetailImage.split(',')[0]
          "
          alt=""
        />
        <img
          class="item_img"
          v-else-if="item.Image"
          :src="
            item.Image.indexOf('cosgz') > -1
              ? item.Image.replace('cosgz', 'cos.ap-guangzhou') + '/pc_img'
              : item.Image
          "
          alt=""
        />

        <div class="item_name">{{ item.Name || '--' }}</div>

        <div class="item_price">
          <div class="one">
            <div class="price">￥{{ item.Price || 0 }}</div>
            <div>零售参考</div>
          </div>
          <div class="two">
            <div>
              <div class="price">
                ￥{{ item.OutPrice2 || item.OutPrice || 0 }}
              </div>
              <div>批发参考</div>
            </div>
            <div>
              <div class="price">
                {{ item.MinAmount || 0 }}
                <span v-if="item.Category1 == '酒类'">瓶</span>
                <span v-else-if="item.Category1 == '茶叶'">斤</span>
                <span v-else-if="item.Category1 == '其他'">
                  {{ item.Name2 || '' }}
                </span>
              </div>
              <div>起批量</div>
            </div>
          </div>
        </div>

        <div class="item_merchants">
          <img
            class="merchants_img"
            :src="item.StoreHeadImage || defaultPic"
            alt=""
            srcset=""
          />

          <div class="merchants_info">
            <div class="name">{{ item.StoreName || '--' }}</div>
            <div class="address">
              <i class="el-icon-location"></i>
              {{ item.Province }}{{ item.City }}{{ item.Area }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="paging" v-if="total > 12">
      <el-pagination
        :page-size="12"
        :pager-count="5"
        layout="prev, pager, next, slot"
        :total="total"
        :hide-on-single-page="true"
        @current-change="changeCurrent"
      >
      </el-pagination>
    </div>

    <AllFooter ref="AllFooter"></AllFooter>

    <el-dialog title=" " :visible.sync="dialogVisible" width="400px">
      <img
        style="width: 320px; margin: 36px auto 0"
        src="../../assets/img/code3.png"
        alt=""
        srcset=""
      />
    </el-dialog>
  </div>
</template>

<script>
// import '../../utils/address.js'
export default {
  data() {
    return {
      defaultPic: 'https://api.chajiuqu.com/uploadImage/img/defaultPict.png',

      query: {
        type: 2,
        status: 1,
        pageindex: 1,
        pagesize: 12,
        storeAuditStatus: 2,
        name: '',
      },
      goodsList: [],

      total: 0,

      dialogVisible: false,
    }
  },

  created() {
    // eslint-disable-next-line no-undef
    // var geolocation = new qq.maps.Geolocation(
    //   'Q3JBZ-7ETKU-QVEV4-23XI2-P4GB3-22BN7',
    //   'web-tea'
    // )

    // geolocation.getLocation(
    //   (res) => {
    //     console.log(res)
    //     // this.getGoodsList(1, '')
    //   },
    //   (err) => {
    //     console.log(err)
    //   }
    // )
    this.getGoodsList(this.query)
  },

  mounted() {},

  methods: {
    // async getGoodsList(latitude, longitude) {
    //   const { data } = await this.$api.goods.getGoodsList({
    //     page: 1,
    //     size: 12,
    //     name: '',
    //     latitude,
    //     longitude,
    //   })
    //   console.log('商品列表', data)
    //   this.goodsList = data
    // },

    async getGoodsList(form) {
      const { data } = await this.$api.goods.getGoodsList(form)
      console.log('商品列表', data)
      this.goodsList = data.list

      this.total = data.count
    },

    changeCurrent(e) {
      console.log(e)
      this.query.pageindex = e
      this.getGoodsList(this.query)
      document.documentElement.scrollTop = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.release {
  width: 100%;

  .goods {
    width: 1126px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .goods_item {
      width: 220px;
      height: 367px;
      padding: 16px 15px 0;
      margin-top: 35px;
      margin-left: 40px;
      background: rgba(247, 247, 247, 1);
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13);
      border-radius: 5px;
      position: relative;
      cursor: pointer;
      text-align: left;

      .item_img {
        width: 220px;
        height: 220px;
        display: block;
        background: rgba(0, 0, 0, 0);
        border-radius: 5px;
      }

      .item_name {
        margin-top: 5px;
        width: 100%;
        height: 30px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 600;
        line-height: 22px;
        color: #000000;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      .item_price {
        width: 100%;
        height: 37px;
        margin-top: 5px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 17px;
        color: #898989;
        display: flex;
        align-items: center;

        .price {
          color: #000000;
          font-size: 14px;
        }

        .one {
          width: 78px;
          border-right: 2px solid #d5d5d5;
        }

        .two {
          margin-left: 20px;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }

      .item_merchants {
        width: 250px;
        height: 63px;
        background: rgba(235, 235, 235, 1);
        border-radius: 0px 0px 5px 5px;
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;

        .merchants_img {
          width: 39px;
          height: 39px;
          display: block;
          border-radius: 50%;
          margin-left: 15px;
        }

        .merchants_info {
          margin-left: 5px;
          flex: 1;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 17px;
          color: #838383;

          .address {
            margin-top: 5px;
            color: #000000;
          }
        }
      }
    }

    .goods_item:nth-child(4n + 1) {
      margin-left: 0;
    }
  }

  .paging {
    width: 1126px;
    margin: 60px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
